import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";

export const ButtonEscolha = ({ existe, handleSelect, parcela, adicionadoPor }) => {
  const [apertou, setApertou] = useState(false);
  const [desativarBotao, setDesativarBotao] = useState(false);

  const buttonProps = {
    onClick: () => {
      handleSelect(parcela);
      setApertou(true);
      setDesativarBotao(true);
    },
    startIcon: existe ? <BlockIcon color="error" /> : apertou ? <CheckCircleIcon color="success" /> : <AddCircleOutlineIcon />,
    color: "success",
    variant: "contained",
    size: "small",
    disabled: existe || desativarBotao,
  };

  const buttonText = existe ? "INDISPONÍVEL" : apertou ? "ADICIONADA" : "SELECIONAR";

  return (
    <>
      {existe ? (
        <Tooltip placement="top" title={`Adicionada por ${adicionadoPor}`} arrow>
          <div style={{ display: 'inline-block' }}>
            <Button {...buttonProps}>
              {buttonText}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <div style={{ display: 'inline-block' }}>
          <Button {...buttonProps}>
            {buttonText}
          </Button>
        </div>
      )}
    </>
  );
};
